import { AsyncAPI } from "../utils";

export const getInitialUser = async (token, ctx) => {
  let { data, errors } = await AsyncAPI({
    method: "GET",
    url: "/",
    auth: true,
    ctx,
  });
  return { data, errors };
};

export const fn_logout = async () => {
  //Will return bool
  let { data, errors } = await AsyncAPI({
    method: "POST",
    url: "/logout",
    auth: true,
  });

  if (data) {
    return { success: true };
  }
  return { success: false };
};
