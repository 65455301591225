import React from "react";

//Scss
import "src/assets/scss/main.scss";
import "src/assets/scss/anti.scss";

//Utils
import { Router } from "next/router";
import nProgress from "nprogress";
import {
  ZustandProvider,
  useCreateStore,
  initializeStore,
} from "src/lib/store";
import { TokenValidation } from "src/lib/utils";
import App from "next/app";
import { getInitialUser } from "src/lib/functions";

// NProgress Loading
nProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", (url) => nProgress.start());
Router.events.on("routeChangeComplete", () => nProgress.done());
Router.events.on("routeChangeError", () => nProgress.done());

import "react-datepicker/dist/react-datepicker.css";

const MyApp = ({ Component, pageProps, globalState }) => {
  //Utils
  const createStore = useCreateStore();

  return (
    <>
      <ZustandProvider createStore={createStore}>
        <Component {...pageProps} />
      </ZustandProvider>
    </>
  );
};

export default MyApp;

// MyApp.getInitialProps = async (appContext) => {
//   const appProps = await App.getInitialProps(appContext);
//   const zustandStore = initializeStore();

//   let { user_status, token } = await TokenValidation(appContext.ctx);
//   //check if login or not
//   if (user_status) {
// let { data, errors } = await getInitialUser(token, appContext.ctx);
//     //Check if data not null
//     if (data?.data?.name) {
//       //Init zustand
//       const { setState } = zustandStore;
//       //Set data to globalZustand;
//       setState({ user_data: { ...data?.data } });
//     } else {
//       //!Error handling
//     }
//   }
//   return {
//     ...appProps,
//     globalState: JSON.parse(JSON.stringify(zustandStore.getState())),
//   };
// };
